import { loadingController, toastController } from '@ionic/core';
import { type MaskitoOptions } from '@maskito/core';
import { API_ENDPOINT } from '../global/config';

/**
 * @description Calls fetch with the following default 
 * @param reqType 
 * @param url 
 * @param body - body is stringified before sending
 * @param headers - overridable, defaults below
 * @example  
 * Content-Type': 'application/json
 * Accept-Charset': 'UTF-8
 * Accept': 'application/json
 * @param authIdToken - include this to send <br/>"Authorization": "Bearer authIdToken" 
 */
export function  makeRequest(
  reqType: "GET" | "POST" | "PUT" | "DELETE",
  url: string, 
  body: Record<string, any> | null = null,
  headers: Record<string, string> | null =  null,
  authIdToken: string | null = null)
  : Promise<Response> {
    // create default headers
    let defaultHeaders: HeadersInit = {
      'Content-Type': 'application/json',
      'Accept-Charset': 'UTF-8',
      'Accept': 'application/json'
    }
    // merge any override headers or new headerspassed in
    let _headers = new Headers({...defaultHeaders, ...headers});
    // add auth token
    if(authIdToken){
      _headers.append("Authorization", `Bearer ${authIdToken}`);
    }
    // create default init
    let reqInit = {
      method: reqType,
      headers: _headers,
    }
    // add json body
    if( body ) {
      reqInit['body'] = JSON.stringify(body);
    }
    // create url
    return fetch(`${API_ENDPOINT}/api${url}`, reqInit);
  }

//function swipeBackListener(ev: Event) {
//  ev.preventDefault();
//  ev.stopPropagation();
//  ev.stopImmediatePropagation();
//  return false;
//}
/**
 * using this stopped all touch interaction and could not scroll
 * removed it all, keeping in case i need that behavior
 * Stop swipe back from working on Ios ( browser back ). Use this
 * on all on-boarding components that should not allow back or really
 * any except login and signup
 * @param el 
 * @param remove - true removes the listener
 */
//export function stopIosSwipeBack(el: Element, remove: boolean = false) {
//  if(!remove) {
//    el.addEventListener   ("touchmove", swipeBackListener, false);
//  }
//  else {
//    el.removeEventListener("touchmove", swipeBackListener, false);
//  }
//}

export async function logToUi(msg="here", pos="top" as const){
  const toast = await toastController.create({
    color: "secondary",
    message: msg,
    buttons:[{role:"cancel", text:"Close"}],
    position: pos
  });
  await toast.present();
  return await toast.onWillDismiss();
}

// allow html in toast message
// do here vs app.ts because regular config is converted to a map and doesn't
// refresh when with browser fro dev
// window["Ionic"].config.set("innerHTMLTemplatesEnabled", true);
// KEEP toast simple, don't use hack above, if we need something more elaborate
// use a different controller

export async function showInfoMsg(msg: string, title: string="") : Promise<any> {
  const toast = await toastController.create({
    color: "secondary",
    header: title,
    message: msg,
    position: 'middle',
    buttons:[{role:"cancel", text:"Close"}],
    duration: 30000
  });
  await toast.present();
  return await toast.onDidDismiss();
}

export async function showErrorMsg(msg: string, title: string="") : Promise<any>{
  const toast = await toastController.create({
    color: "quinary",
    header: title,
    message: msg,
    buttons:[{role:"cancel", text:"Close"}],
    position: 'middle',
    duration: 60000
    // duration: 20000
  });
  await toast.present();
  return await toast.onDidDismiss();
}

/**
 * Using toastController places toast as child of <ion-app>
 * A modal's parent is body, so for toast to show in a modal
 * we must do it this way.
 * @param msg 
 * @param title 
 */
export async function showErrorMsgInModal(msg: string, title: string="") : Promise<any>{
  const toast = document.createElement("ion-toast");
  toast.color = "quinary";
  toast.header = title;
  toast.message = msg;
  toast.buttons = [{role:"cancel", text:"Close"}];
  toast.position = 'middle';
  toast.duration = 20000;
  document.body.appendChild(toast);  
  await toast.present();
  return await toast.onDidDismiss();
}

export async function showLoading(message:string) {
  const loading = await loadingController.create({
    message: message,
    translucent: true,
  });
  await loading.present();
  return loading;
}

export async function showLoadingInModal(message:string) {
  const loading = document.createElement('ion-loading');
  loading.message = message;
  loading.translucent = true;
  document.body.appendChild(loading);  
  await loading.present();
  return loading;
}

export interface TextMaskConfig {
  inputElement: HTMLInputElement | HTMLIonInputElement;
  mask: Array<string | RegExp> | ((raw: string) => Array<string | RegExp>) | false;
  guide?: boolean;
  placeholderChar?: string;
  pipe?: (conformedValue: string, config: TextMaskConfig) => false | string | object;
  keepCharPositions?: boolean;
  showMask?: boolean;
}
 
export const phoneMask:MaskitoOptions = {
    mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, ],
};

/**
 * returns the given date if provided or default todays date
 * in YYYY-MM-DD format
 */
export const convertDateToISOString = (date: Date = new Date()): string => {
  const mm = date.getMonth() < 9 ? "0" + (date.getMonth() +1) : (date.getMonth() + 1);
  const dd  = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  return `${date.getFullYear()}-${mm}-${dd}`;
}

/**
 * given a date string in format YYYY-MM-DD, convert to local date
 * NOT like new Date("2019-05-02") returns the day before which is true ISO 
 * so we parse out year, month, day and create new date that gives us local date
 * @param ISOdateString 
 */
export const convertISOStringtoDate = (ISOdateString: string): Date => {
  const yyyymmdd: string[] = ISOdateString.split('-');
  const y = parseInt(yyyymmdd[0]);
  const m = parseInt(yyyymmdd[1]);
  const d = parseInt(yyyymmdd[2]);
  const date = new Date(y,m-1,d);
  return date;
}

export const toTitleCase = (str:string) => {
  return str.replace(
    /\w\S*/g,// match word space 
    function(txt) { // capitalize the substring
      return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
    }
  );
}